/* Skills.css */

.skills-section {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between each skills category */
    padding: 20px; /* Padding around the entire skills section */
    max-width: 800px; /* Maximum width of the skills section */
    margin: auto; /* Center the skills section */
}

.skills-category {
    border: 1px solid #ddd; /* Light border around each category */
    padding: 15px; /* Padding inside each category */
    border-radius: 8px; /* Rounded corners for the category boxes */
    background-color: #f9f9f9; /* Light background for each category */
}

.skills-category h3 {
    margin-top: 0; /* Remove the top margin from category titles */
    color: #333; /* Darker text color for better readability */
}

.skills-container {
    display: flex;
    flex-wrap: wrap; /* Allow skill items to wrap to the next line */
    gap: 10px; /* Space between individual skill items */
    margin-top: 10px; /* Space between the title and skill items */
}

.skill-item {
    padding: 5px 10px; /* Padding inside each skill item */
    background-color: #eee; /* Light background for skill items */
    border-radius: 5px; /* Rounded corners for skill items */
    font-size: 14px; /* Smaller font size for skill items */
    color: #555; /* Dark gray color for skill item text */
}
