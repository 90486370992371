/* Add your CSS styles here */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: #333;
  color: white;
  padding: 1rem;
  text-align: center;
}

nav {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

nav a {
  margin: 0 1rem;
  color: white;
  text-decoration: none;
}

section {
  margin: 2rem;
}

.blog-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.blog-title {
  font-size: 2em;
  color: #333;
  margin-bottom: 1em;
}

.blog-section {
  margin-bottom: 2em;
}

.blog-subtitle {
  font-size: 1.5em;
  color: #555;
  margin-bottom: 0.5em;
}

.blog-content {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
}
/* Style for the dropdown button */
.dropbtn {
  background-color: #333;
  color: white;
  padding: 0;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Align dropdown to the right, with some space */
.dropdown {
  margin-left: auto;
  margin-right: 20px; /* Adjust this value as needed */
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  right: 0; /* Aligns the dropdown content to the right of the dropdown button */
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Optional: Show the dropdown menu when clicked */
.dropdown-clicked .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown-clicked .dropbtn {
  background-color: #3e8e41;
}

