/* About.css */

#about-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    color: lightsteelblue;
    background: #333333;
    padding: 20px;
    border-radius: 10px;
    font-family: monospace;
    max-width: 700px;
}

#about-text {
    text-align: justify-all;
    margin: 10px 0;
    max-width: 700px;
    color: white;
    font-family: "Times New Roman", serif;

}
