/* Base styles for the contact form container */
.contact-form {
    display: flex;
    flex-direction: column;
    max-width: 800px; /* Wider form */
    width: 100%; /* Makes sure the form takes the full width on smaller screens */
    background: linear-gradient(to bottom right, #ffffff, #f7f7f7); /* Subtle gradient background */
    padding: 40px; /* More internal space */
    border-radius: 8px; /* Slightly larger border radius for a softer look */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Larger shadow for more depth */
    margin: 2rem auto; /* Center the form on the page */
    box-sizing: border-box; /* Makes sure padding doesn't affect the width */
}

/* Input and Textarea styles */
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: calc(100% - 16px); /* Full width minus the padding */
    margin-bottom: 16px;
    padding: 12px; /* More padding for a larger touch area and better readability */
    border: 1px solid #dcdcdc; /* Lighter border color */
    border-radius: 4px;
    background: #f8f8f8;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form textarea:focus {
    border-color: #a3a3a3; /* Change border color on focus for better visual feedback */
    outline: none; /* Remove default focus outline */
}

/* Error message styles */
.contact-form .error {
    color: #e74c3c; /* Use hex color for consistency */
    margin-top: -8px;
    margin-bottom: 16px;
    font-size: 0.875em;
}

/* Button styles */
.contact-form button {
    background-color: #007bff;
    color: white;
    padding: 15px 30px; /* Larger padding for a bigger button */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1em; /* Larger font size for button text */
    font-weight: bold; /* Make the button text bold */
    letter-spacing: 0.05em; /* Add some letter-spacing for style */
    width: auto; /* Allow the button to size with its content */
    align-self: center; /* Align the button to the center of the form */
}

.contact-form button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow effect on hover */
}

/* Success message styles */
.contact-form .success {
    color: #2ecc71; /* Use hex color for consistency */
    margin-bottom: 16px;
    font-size: 0.875em;
}
