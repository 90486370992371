.blog-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.blog-title {
    font-size: 2em; /* You can adjust this size for smaller screens using a media query if needed */
    color: #333;
    margin-bottom: 1em;
}

.blog-architecture-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 2em;
}

.blog-section {
    margin-bottom: 2em;
}

.blog-subtitle {
    font-size: 1.5em;
    color: #555;
    margin-bottom: 0.5em;
}

.blog-content {
    font-size: 1em;
    line-height: 1.6;
    color: #666;
}

/* Responsive adjustments for smaller screens, e.g., mobile devices */
@media (max-width: 768px) {
    .blog-container {
        padding: 10px;
        max-width: 100%;
    }

    .blog-title, .blog-subtitle {
        font-size: 1.5em; /* Adjust font size for readability on smaller screens */
    }

    .blog-content {
        font-size: 0.9em; /* Adjust font size for readability on smaller screens */
    }

    /* Additional responsive image styling if needed */
    .blog-architecture-image {
        max-width: 100%;
        height: auto; /* This maintains the aspect ratio */
    }
}
